<template>
  <div class="before-after-container" style="height: auto">
    <ClientOnly>
      <ImgComparisonSlider class="img-comparison-slider" :value="imgAnim.value" :handle="true">
        <WpImage slot="first" :img="before?.node"></WpImage>
        <WpImage slot="second" :img="after?.node"></WpImage>
      </ImgComparisonSlider>
    </ClientOnly>
  </div>
</template>
<style lang="scss">
.img-comparison-slider {
  --divider-width: 5px;
  --divider-color: #fff;
  --default-handle-opacity: 1;
  --default-handle-width: 100px;

  .handle {
    stroke-width: 3px !important;
  }
}
</style>
<script setup lang="ts">
import {ImgComparisonSlider} from "@img-comparison-slider/vue";

const imgAnim = ref({
  value: "0%",
});
const {$anime} = useNuxtApp();
defineProps({
  before: {
    type: Object as PropType<ImgType>,
    required: true,
  },
  after: {
    type: Object as PropType<ImgType>,
    required: true,
  },
});
onMounted(() => {
  $anime({
    targets: imgAnim.value,
    value: [0, 60, 50],
    duration: 1500,
    delay: 2000,
    easing: "easeInOutQuad",
    update: function () {
    },
  });  
});

</script>
